import React from 'react'
import { useModalState } from '../../context/ModalContext'
import dynamic from 'next/dynamic'
import { SCREEN } from '../common/forms/OnboardingFormUi'

const OnboardingModal = dynamic(() => import('./OnboardingModal'), { ssr: false })

interface Props {
  onShowScreen?: (screen: number) => void
}

const DynamicOnboardingModal: React.FC<Props> = ({ onShowScreen }) => {
  const modalState = useModalState()
  const isModalOpen = modalState?.onboarding
  const defaultScreen = SCREEN.SCENARIOS

  return isModalOpen ? (
    <OnboardingModal defaultScreen={defaultScreen} onShowScreen={onShowScreen} />
  ) : null
}

export default DynamicOnboardingModal
