import React from 'react'
import { css } from '@emotion/core'
import LocationInputUi from '../../../../components/common/LocationInputUi'
import Heading from '../../../../components/common/Heading'
import { RangeSlider } from '../../../../components/common/RangeSlider'
import Grid from '../../../../components/common/Grid'

import { DESKTOP_OR_MORE } from '~/constants/theme.styles'
import { useSearchParamsState } from '~/context/SearchParamsContext'
import { LocationRadius } from '~/generated/graphql'

const sliderLabelStyles = (alignment: string) => css`
  width: 100%;
  color: #839399;
  font-size: 13px;
  line-height: 18px;
  text-align: ${alignment};
`

const locationRadiusNumberToString: { [n: number]: LocationRadius } = {
  101: LocationRadius.AnyLocation,
  100: LocationRadius.Within_100Miles,
  50: LocationRadius.Within_50Miles,
  25: LocationRadius.Within_25Miles,
  10: LocationRadius.Within_10Miles,
  5: LocationRadius.Within_5Miles
}

const locationKmRadiusNumberToString: { [n: number]: LocationRadius } = {
  101: LocationRadius.AnyLocation,
  100: LocationRadius.Within_100Kms,
  50: LocationRadius.Within_50Kms,
  25: LocationRadius.Within_25Kms,
  10: LocationRadius.Within_10Kms,
  5: LocationRadius.Within_5Kms
}

const locationRadiusStringToNumber: { [n: string]: number } = {
  ANY_LOCATION: 101,
  WITHIN_100_MILES: 100,
  WITHIN_50_MILES: 50,
  WITHIN_25_MILES: 25,
  WITHIN_10_MILES: 10,
  WITHIN_5_MILES: 5,
  WITHIN_100_KMS: 100,
  WITHIN_50_KMS: 50,
  WITHIN_25_KMS: 25,
  WITHIN_10_KMS: 10,
  WITHIN_5_KMS: 5
}

interface Props {
  location: string
  locationRadius: LocationRadius
  countryRestrictions: string[]
  onSubmit?: () => void
  onLocationChange?: (location: string, searchCountry: string) => void
  onLocationRadiusChange?: (locationRadius: LocationRadius) => void
  onCompareChange?: (compare: boolean) => void
}

export const ConfirmLocation: React.FC<Props> = ({
  location,
  locationRadius,
  countryRestrictions,
  onSubmit,
  onLocationChange,
  onLocationRadiusChange,
  onCompareChange
}) => {
  const { tempSearchParams } = useSearchParamsState()

  const handleLocationInputChange = ({ inputName, address, searchCountry }: any) => {
    if (inputName === 'location') {
      onLocationChange?.(address, searchCountry)
    }
  }

  const handleSubmit = ({ inputName, address, searchCountry }: any) => {
    if (inputName === 'location') {
      onLocationChange?.(address, searchCountry)
      onSubmit?.()
    }
  }

  return (
    <div>
      <Heading
        as="h2"
        size="medium"
        css={css`
          max-width: 450px;
          margin-left: auto;
          margin-right: auto;
          font-size: 22px;
          font-weight: 600;
          margin-top: 20px !important;
          margin-bottom: 40px;
          text-align: center;
          @media ${DESKTOP_OR_MORE} {
            margin-top: 10px !important;
            font-size: 32px;
          }
        `}
      >
        Let's confirm your location!
      </Heading>
      <div
        css={css`
          max-width: 400px;
          margin-left: auto;
          margin-right: auto;
          box-shadow: none;
        `}
      >
        <LocationInputUi
          id="onboardingConfirmLocation"
          inputTestKey="onboarding-modal-location"
          countryRestrictions={countryRestrictions}
          value={location}
          name="location"
          loadingSpinner="disabled"
          onSelect={({ inputName, address, searchCountry }) => {
            handleLocationInputChange({ inputName, address, searchCountry })
          }}
          onEnterKeySubmitAction={({ inputName, address, searchCountry }) => {
            handleSubmit({ inputName, address, searchCountry })
          }}
          attachInputProps={{
            inputSize: 'large',
            autoComplete: 'x',
            autoFocus: true,
            disabled: false,
            css: css`
              border: 2px solid #000;

              &:active,
              &:focus {
                border-color: #000;
              }

              @media ${DESKTOP_OR_MORE} {
                padding: 1.5em 1em 1.5em 3.5em;
              }
            `,
            fluid: true
          }}
          fluid
        />
      </div>
      <div
        css={css`
          max-width: 400px;
          margin-left: auto;
          margin-right: auto;

          /* Hide slider on IE */
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: none;
          }

          @media ${DESKTOP_OR_MORE} {
            margin-top: 20px;
          }
        `}
      >
        <div
          css={css`
            margin: 27px 0 10px;
          `}
        >
          <span
            css={css`
              height: 24px;
              width: 49px;
              color: #839399;
              font: 16px Lato, sans-serif;
              line-height: 24px;
            `}
          >
            Within
          </span>
          <span
            css={css`
              color: #8053fd;
              font: bold 16px Lato, sans-serif;
              line-height: 24px;
              margin: 0 5px 0 15px;
            `}
          >
            {locationRadius === 'ANY_LOCATION'
              ? 'Any (100+)'
              : locationRadiusStringToNumber[locationRadius]}
          </span>
          <span
            css={css`
              color: #0e1111;
              font: bold 16px Lato, sans-serif;
              line-height: 24px;
            `}
          >
            {tempSearchParams.searchCountry === 'us' || tempSearchParams.searchCountry === 'zz'
              ? 'miles'
              : 'Km'}
          </span>
        </div>
        <RangeSlider
          value={locationRadiusStringToNumber[locationRadius]}
          min={5}
          max={101}
          options={{ 0: 5, 20: 10, 40: 25, 60: 50, 80: 100, 100: 101 }}
          onChange={value => {
            const radiusValue =
              tempSearchParams.searchCountry === 'us' || tempSearchParams.searchCountry === 'zz'
                ? locationRadiusNumberToString[value]
                : locationKmRadiusNumberToString[value]
            onLocationRadiusChange?.(radiusValue)
          }}
          hasStartCircle
          hasEndCaret
        />

        <Grid between="all">
          <Grid.Col xs={6} md={6}>
            <span css={sliderLabelStyles('left')}>5</span>
          </Grid.Col>
          <Grid.Col xs={6} md={6}>
            <span css={sliderLabelStyles('right')}>101+</span>
          </Grid.Col>
        </Grid>
      </div>
    </div>
  )
}
