import React from 'react'
import { css } from '@emotion/core'
import Heading from '../../../../components/common/Heading'

import env from '~/constants/env'
const PUBLIC_ASSET_PREFIX = env.PUBLIC_ASSET_PREFIX
import { DESKTOP_OR_MORE, TABLET_OR_LESS } from '~/constants/theme.styles'

interface Props {
  entryLevelScenario: boolean
  remoteScenario: boolean
  covidScenario: boolean
}

const PlusIcon = () => (
  <img
    src={`${PUBLIC_ASSET_PREFIX}/static/images/plus-icon.svg`}
    alt=""
    css={css`
      fill: #353a3c;
      width: 14px;
      @media ${TABLET_OR_LESS} {
        height: 14px;
        margin: 20px 20px;
      }
      @media ${DESKTOP_OR_MORE} {
        width: 18px;
        height: 18px;
        margin: 28px 20px;
      }
    `}
  />
)
const RemoteIcon = () => (
  <img
    src={`${PUBLIC_ASSET_PREFIX}/static/images/remote.svg`}
    alt="Remote"
    css={css`
      width: 56px;
      height: 56px;
      @media ${DESKTOP_OR_MORE} {
        width: 70px;
        height: 70px;
      }
    `}
  />
)
const CovidIcon = () => (
  <img
    src={`${PUBLIC_ASSET_PREFIX}/static/images/covid.svg`}
    alt="COVID"
    css={css`
      width: 56px;
      height: 56px;
      @media ${DESKTOP_OR_MORE} {
        width: 70px;
        height: 70px;
      }
    `}
  />
)
const EntryLevelIcon = () => (
  <img
    src={`${PUBLIC_ASSET_PREFIX}/static/images/new_grad.svg`}
    alt="Entry-level"
    css={css`
      width: 56px;
      height: 56px;
      @media ${DESKTOP_OR_MORE} {
        width: 70px;
        height: 70px;
      }
    `}
  />
)

export const NotAlone: React.FC<Props> = ({
  entryLevelScenario,
  remoteScenario,
  covidScenario
}) => (
  <div
    css={css`
      text-align: center;
    `}
  >
    <Heading
      as="h2"
      css={css`
        font-size: 22px;
        font-weight: 600;
        margin-top: 20px !important;
        margin-bottom: 40px;
        text-align: center;
        @media ${DESKTOP_OR_MORE} {
          margin-top: 10px !important;
          font-size: 32px;
        }
      `}
    >
      You're not alone!
    </Heading>
    <div
      css={css`
        margin: 40px 0;
      `}
    >
      {remoteScenario ? (
        <>
          <RemoteIcon />
          {covidScenario || entryLevelScenario ? <PlusIcon /> : null}
        </>
      ) : null}
      {covidScenario ? (
        <>
          <CovidIcon />
          {entryLevelScenario ? <PlusIcon /> : null}
        </>
      ) : null}
      {entryLevelScenario ? (
        <>
          <EntryLevelIcon />
        </>
      ) : null}
    </div>
    <p
      css={css`
        font-size: 17px;
        line-height: 26px;

        span {
          display: inline;
        }

        @media ${DESKTOP_OR_MORE} {
          font-size: 20px;
          line-height: 32px;

          span {
            display: block;
          }
        }
      `}
    >
      {remoteScenario || entryLevelScenario || covidScenario ? (
        <>
          Millions of Americans are in your situation looking for{' '}
          <span>
            {remoteScenario ? 'remote ' : ''}work{entryLevelScenario ? ' as a recent grad' : ''}
            {covidScenario ? ' due to COVID' : ''}.
          </span>
        </>
      ) : (
        <>Millions of Americans are in your situation looking for work.</>
      )}
    </p>

    <p
      css={css`
        margin-top: 40px;
        font-size: 17px;
        line-height: 26px;
        font-weight: bold;

        span {
          display: inline-block;
        }

        @media ${DESKTOP_OR_MORE} {
          font-size: 20px;
          line-height: 32px;

          span {
            display: block;
          }
        }
      `}
    >
      We’ll help you find the right job <span>by collecting some preferences next.</span>
    </p>
  </div>
)
