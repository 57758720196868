import React from 'react'
import { css } from '@emotion/core'
import { useTabs } from './Tabs'
import { EmotionStyles } from '../../../types/types'

export interface IPanelProps {
  id: number | string
  css?: EmotionStyles
}

export const TabPanel: React.FC<IPanelProps> = ({ id, children, ...rest }) => {
  const { activeId } = useTabs()
  return activeId === id ? (
    <div
      css={css`
        display: ${activeId === id ? 'block' : 'none'};
        height: 100%;
        width: 100%;
      `}
      {...rest}
    >
      {children}
    </div>
  ) : null
}
