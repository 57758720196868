import React from 'react'
import { css, SerializedStyles } from '@emotion/core'

type IProps = {
  title?: React.ReactChild
  visible?: boolean
  css?: SerializedStyles
  children?: React.ReactChild
  arrowPlacement?: string
}

const Tooltip: React.FC<IProps> = ({
  title,
  children,
  arrowPlacement,
  visible = true,
  ...rest
}) => {
  return (
    <div
      css={css`
        display: ${visible ? 'block' : 'none'};
        position: relative;
        background: #e7e9f0;
        margin-top: 17px;
        border-radius: 7px;
        padding: 18px;
      `}
      {...rest}
    >
      <div
        css={css`
          color: #839399;
          text-transform: uppercase;
          margin-bottom: 8px;
          font-size: 13px;
          line-height: 18px;
        `}
      >
        {title}
      </div>
      <div
        css={css`
          color: #353a3c;
          font-size: 13px;
          line-height: 18px;
        `}
      >
        {children}
      </div>
      {arrowPlacement ? (
        <div
          css={[
            css`
              width: 0px;
              height: 0px;
              display: block;
              position: absolute;
              top: -10px;
              border-style: solid;
              border-width: 0px 10px 10px 10px;
              border-bottom-color: #e7e9f0;
              border-left-color: transparent;
              border-top-color: transparent;
              border-right-color: transparent;
            `,
            arrowPlacement === 'top-start'
              ? css`
                  left: 70px;
                `
              : arrowPlacement === 'top-end'
              ? css`
                  right: 70px;
                `
              : css`
                  left: calc(50% - 10px);
                `
          ]}
        />
      ) : null}
    </div>
  )
}

export { Tooltip }
