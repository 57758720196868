import React from 'react'
import { css } from '@emotion/core'

import env from '~/constants/env'
const PUBLIC_ASSET_PREFIX = env.PUBLIC_ASSET_PREFIX
import { DESKTOP_OR_MORE } from '~/constants/theme.styles'

export const FindYouJob = () => (
  <div
    css={css`
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
    `}
  >
    <div
      css={css`
        text-align: center;
      `}
    >
      <img
        css={css`
          display: inline-block;
          margin-left: auto;
          margin-right: auto;
          margin-top: 20px;
          width: 60%;
          @media ${DESKTOP_OR_MORE} {
            margin-top: 20px;
            margin-bottom: 17px;
          }
        `}
        src={`${PUBLIC_ASSET_PREFIX}/static/images/search-intro.svg`}
        alt="Let's find you a job!"
      />
    </div>
    <h2
      css={css`
        font-weight: 700;
        font-size: 30px;
        line-height: 30px;
        margin-top: 30px;

        @media (min-width: 375px) {
          font-size: 36px;
        }

        @media ${DESKTOP_OR_MORE} {
          font-size: 40px;
          line-height: 40px;
          margin-bottom: 10px;
          margin-top: 20px;
        }
      `}
    >
      Let’s find you a job!
    </h2>
    <p
      css={css`
        font-size: 16px;
        line-height: 24px;
        color: #839399;
        margin-top: 20px;
      `}
    >
      Finding a job online can be complicated and time consuming. Tell us about your situation and
      we’ll guide you toward success.
    </p>
  </div>
)
