import React, { useState } from 'react'
import { css } from '@emotion/core'
import Heading from '../../../../components/common/Heading'
import Label from '../../../../components/common/Label'
import Icon from '../../../../components/common/Icon'
import Spreader from '../../../../components/common/Spreader'
import withUserDevice, { UserDeviceProps } from '../../../../components/hoc/withUserDevice'
import { DESKTOP_OR_MORE } from '~/constants/theme.styles'
import { KeywordSuggestionInput } from '~/components/common/KeywordSuggestionInput/KeywordSuggestionInput'

const RadioLabel = ({ selected, children, onClick, ...rest }: any) => (
  <Label
    as="button"
    size="large"
    color={selected ? 'purpleLight' : undefined}
    rounded
    onClick={onClick}
    css={css`
      font-size: 14px;
      @media ${DESKTOP_OR_MORE} {
        font-size: 20px;
        line-height: 24px;
        padding: 0.2em 0.6em;
      }
    `}
    {...rest}
  >
    {selected && (
      <Icon
        size="medium"
        icon="checkmark"
        color="purpleDark"
        css={css`
          margin-right: 8px;
          height: 12px;
        `}
      />
    )}
    {children}
  </Label>
)

interface Props {
  keyword: string
  requireKeywordTreatment: boolean
  covidScenario: boolean
  entryLevelScenario: boolean
  remoteScenario: boolean
  onSubmit?: () => void
  onUpdateKeyword?: (name: string, type: string) => void
  onCountryChange?: (name: string) => void
  onCompareChange?: (value: boolean) => void
}

type ExtendedProps = Props & UserDeviceProps

export const WhatRolePure: React.FC<ExtendedProps> = ({
  keyword = '',
  requireKeywordTreatment,
  userDevice,
  covidScenario,
  entryLevelScenario,
  remoteScenario,
  onUpdateKeyword,
  onSubmit
}) => {
  const [showKeywordMissingError, setShowKeywordMissingError] = useState(false)

  const handleKeywordChange = (value: string, type?: string): void => {
    onUpdateKeyword?.(value, type || 'Unknown')

    if (requireKeywordTreatment === true) {
      if (value.length > 0) {
        setShowKeywordMissingError(false)
      } else {
        setShowKeywordMissingError(true)
      }
    }
  }

  const handleLabelClick = (value: string) => {
    onUpdateKeyword?.(value, 'Pill')
  }

  let suggestionText = ''
  let suggestions = []

  if (entryLevelScenario) {
    suggestionText = 'Not sure? Here are some popular ones for new grads:'
    suggestions = ['Marketing', 'Analyst', 'Sales', 'Assistant', 'Customer Service']
  } else if (covidScenario) {
    suggestionText = 'Not sure? Here are some popular ones hiring urgently:'
    suggestions = ['Work From Home', 'Part-Time', 'Delivery', 'Warehouse', 'Grocery']
  } else if (remoteScenario) {
    suggestionText = 'Not sure? Here are some popular ones for remote work:'
    suggestions = ['Sales', 'Customer Service', 'Marketing', 'Web Developer', 'Designer']
  } else {
    suggestionText = 'Not sure? Here are some popular ones:'
    suggestions = ['Work From Home', 'Part-Time', 'Delivery', 'Warehouse', 'Grocery']
  }

  return (
    <div>
      <Heading
        as="h2"
        size="medium"
        css={css`
          font-size: 20px;
          font-weight: 600;
          margin-top: 23px !important;
          margin-bottom: 40px;
          text-align: center;
          @media ${DESKTOP_OR_MORE} {
            margin-top: 10px !important;
            font-size: 32px;

            span {
              display: inline-block;
            }
          }
        `}
      >
        Let's add a job title <span>or keyword...</span>
      </Heading>
      <div
        css={css`
          max-width: 400px;
          margin-left: auto;
          margin-right: auto;
        `}
      >
        <KeywordSuggestionInput
          inputTestKey="onboarding-modal-keyword"
          keyword={keyword}
          maxSuggestions={6}
          shouldAutoFocus={true}
          showKeywordMissingError={showKeywordMissingError || false}
          onChange={handleKeywordChange}
          onSubmit={onSubmit}
          attachInputProps={{ inputSize: 'large' }}
          inputCss={css`
            @media ${DESKTOP_OR_MORE} {
              padding: 1.5em 1em 1.5em 3.5em;
            }
          `}
        />
      </div>
      <div
        css={css`
          max-width: 500px;
          margin-left: auto;
          margin-right: auto;
        `}
      >
        <p
          css={css`
            color: #839399;
            font-family: Lato;
            font-size: 15px;
            text-align: center;
            letter-spacing: 0;
            line-height: 24px;
            margin: 30px 0 15px 0;

            @media ${DESKTOP_OR_MORE} {
              font-size: 20px;
              line-height: 30px;
              margin-top: 50px;
              margin-bottom: 20px;
            }
          `}
        >
          {suggestionText}
        </p>
      </div>
      <Spreader
        distribution="center"
        spacing="veryTight"
        wrap="all"
        css={css`
          max-width: 800px;
          margin-left: auto;
          margin-right: auto;
        `}
      >
        {suggestions.map((item, index) => (
          <Spreader.Item key={item}>
            <RadioLabel
              data-cy={`onboarding-modal-keyword-choice-${index + 1}`}
              onClick={() => handleLabelClick(item)}
              selected={keyword === item}
            >
              {item}
            </RadioLabel>
          </Spreader.Item>
        ))}
      </Spreader>
    </div>
  )
}

export const WhatRole = withUserDevice(WhatRolePure)
