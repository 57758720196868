import React from 'react'
import { css } from '@emotion/core'
import { RadioTile } from '../../../../components/common/RadioTile'
import Grid from '../../../../components/common/Grid'
import Heading from '../../../../components/common/Heading'

import env from '~/constants/env'
const PUBLIC_ASSET_PREFIX = env.PUBLIC_ASSET_PREFIX
import { DESKTOP_OR_MORE } from '~/constants/theme.styles'

interface Props {
  activeId?: string
  onOptionChange?: (name: string) => void
}

export const WhatKind: React.FC<Props> = ({ activeId, onOptionChange }) => (
  <div>
    <Heading
      as="h2"
      size="medium"
      css={css`
        font-size: 22px;
        font-weight: 600;
        margin-top: 20px !important;
        margin-bottom: 40px;
        text-align: center;

        span {
          display: block;
        }

        @media ${DESKTOP_OR_MORE} {
          margin-top: 10px !important;
          font-size: 32px;

          span {
            display: inline-block;
          }
        }
      `}
    >
      What kinds of jobs <span>are you looking for?</span>
    </Heading>
    <div
      css={css`
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
      `}
    >
      <Grid gap={17}>
        <Grid.Col xs={12} md={12}>
          <RadioTile
            data-cy="onboarding-specific-job"
            isInactive={activeId && activeId !== 'Specific' ? true : false}
            isSelected={activeId === 'Specific' ? true : false}
            onClick={() => onOptionChange?.('Specific')}
          >
            <p
              css={css`
                padding: 15px 13px 15px 140px;
                background: url('${PUBLIC_ASSET_PREFIX}/static/images/onboarding-role-specific.svg') no-repeat 0px 50%;
                background-size: auto 80%;
                font-size: 15px;
                line-height: 24px;
                height: 80px;
                @media (min-width: 375px) {
                  font-size: 16px;
                }
                @media ${DESKTOP_OR_MORE} {
                  padding: 25px 13px 25px 180px;
                  font-size: 18px;
                  line-height: 24px;
                  height: 94px;
                }
                span {
                  display: block;
                }
              `}
            >
              I have <strong>specific</strong> <span>roles in mind</span>
            </p>
          </RadioTile>
        </Grid.Col>
        <Grid.Col xs={12} md={12}>
          <RadioTile
            data-cy="onboarding-any-job"
            isInactive={activeId && activeId !== 'Anything' ? true : false}
            isSelected={activeId === 'Anything' ? true : false}
            onClick={() => onOptionChange?.('Anything')}
          >
            <p
              css={css`
                padding: 15px 13px 15px 140px;
                background: url('${PUBLIC_ASSET_PREFIX}/static/images/onboarding-role-anything.svg') no-repeat 0px 50%;
                background-size: auto 80%;
                line-height: 24px;
                font-size: 15px;
                height: 80px;
                @media (min-width: 375px) {
                  font-size: 16px;
                }
                @media ${DESKTOP_OR_MORE} {
                  padding: 25px 13px 25px 180px;
                  font-size: 18px;
                  line-height: 24px;
                  height: 94px;
                }
                span {
                  display: block;
                }
              `}
            >
              I'm open to{' '}
              <span>
                <strong>almost anything</strong>
              </span>
            </p>
          </RadioTile>
        </Grid.Col>
      </Grid>
    </div>
  </div>
)
