import React from 'react'

interface ITabsContext {
  total?: number
  activeId?: number | string
  showTab: (id: number | string) => void
  hideTab: (id: number | string) => void
  toggleTab: (id: number | string) => void
  prevTab: () => void
  nextTab: () => void
}

const TabsContext = React.createContext<ITabsContext | undefined>(undefined)

interface Props {
  total?: number
  defaultId?: number | string
}

const Tabs: React.FC<Props> = props => {
  const { defaultId, total } = props
  const [activeId, setActiveId] = React.useState(defaultId)

  const showTab = (id: number | string) => {
    setActiveId(id)
  }

  const hideTab = (id: number | string) => {
    if (activeId === id) {
      setActiveId(defaultId)
    }
  }

  const toggleTab = (id: number | string) => {
    activeId === id ? hideTab(id) : showTab(id)
  }

  const prevTab = () => {
    if (!total || typeof activeId !== 'number') {
      throw 'Tabs needs a numeric total and IDs to do this action'
    }

    if (activeId === 0) return
    setActiveId(activeId ? activeId - 1 : 0)
  }

  const nextTab = () => {
    if (!total || typeof activeId !== 'number') {
      throw 'Tabs needs a numeric total and IDs to do this action'
    }

    if (activeId === total - 1) return
    setActiveId(activeId ? activeId + 1 : 0)
  }

  const memoizedContextValue = React.useMemo(
    () => ({
      total,
      defaultId,
      activeId,
      showTab,
      hideTab,
      toggleTab,
      prevTab,
      nextTab
    }),
    [activeId, showTab]
  )

  return <TabsContext.Provider value={memoizedContextValue}>{props.children}</TabsContext.Provider>
}

export const useTabs = (): ITabsContext => {
  const context = React.useContext(TabsContext)
  if (!context) {
    throw new Error('This component must be used within a <Tabs> component.')
  }
  return context
}

export { Tabs, TabsContext }
