import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'

import env from '~/constants/env'
const PUBLIC_ASSET_PREFIX = env.PUBLIC_ASSET_PREFIX
import {
  BLACK,
  PURPLE,
  DESKTOP_OR_MORE,
  TABLET_OR_LESS,
  GRAY_MEDIUM_DARK,
  TABLET_OR_MORE
} from '../../../constants/theme.styles'

import { Tabs, TabPanel, useTabs } from '../../../components/common/Tabs'
import Button from '../../../components/common/Button'
import { Mixpanel } from '../../../lib/mixpanel'
import { EmotionStyles } from '../../../types/types'
import { FilterGroup } from '../../../components/common/FilterGroup'
import {
  FindYouJob,
  WhatKind,
  Scenarios,
  NotAlone,
  WhatRole,
  HowQuickly,
  ConfirmLocation
} from '../../../components/domain/search/OnboardingWizard'
import { useUpdateEffect } from '~/components/hooks/useUpdateEffect'
import Heading from '../Heading'
import { Tooltip } from '../Tooltip'
import Icon from '../Icon'
import Grid from '../Grid'
import { useSearchParams } from '~/context/SearchParamsContext'
import { getCountryRestrictions } from '~/lib/utils/location'
import { usePersonaActions, usePersonaState } from '~/context/PersonaContext'

export const SCREEN = {
  LANDING: 1,
  HOW_QUICKLY: 2,
  WHAT_KIND: 3,
  WHAT_ROLE: 4,
  CONFIRM_LOCATION: 5,
  JOB_PREFERENCES: 6,
  WORKPLACE_PREFERENCES: 8,
  SCENARIOS: 13,
  NOT_ALONE: 14,
  PHYSICAL_LABOR: 15
}

const KEYWORD_EXCLUSION_LIST: { [key: string]: boolean } = {
  any: true,
  all: true,
  anything: true,
  job: true,
  jobs: true,
  'any job': true,
  'all jobs': true,
  'view all jobs': true,
  hiring: true,
  'now hiring': true,
  open: true,
  work: true,
  worker: true,
  general: true
}

const headerIconStyles = css`
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  .icon-inner-fill {
    fill: #839399 !important;
  }
  &:hover {
    .icon-inner-fill {
      fill: ${GRAY_MEDIUM_DARK} !important;
    }
  }
`

const ProgressBar = ({
  current,
  total,
  barCss,
  ...rest
}: {
  current: number
  total: number
  barCss?: EmotionStyles
}) => (
  <div
    css={css`
      width: 100%;
      height: 4px;
      border-radius: 2px;
      background-color: #e7e9f0;
      overflow: hidden;
      transition: all 500ms cubic-bezier(0.64, 0.04, 0.35, 1);
    `}
    {...rest}
  >
    <div
      css={[
        css`
          height: 4px;
          width: ${(current / total) * 100}%;
          background-color: #8053fd;
          border-radius: 2px;
          transition: all 300ms cubic-bezier(0.64, 0.04, 0.35, 1);
        `,
        barCss
      ]}
    ></div>
  </div>
)

const errorViewStyles = css`
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  padding: 4px 5px;
  background-color: #fd5353;
  border-radius: 5px;
  overflow: hidden;
  & + div input {
    color: ${PURPLE};
    border: 1px solid ${PURPLE};
  }
`

const filterGroupStyles = css`
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 2px 4px -1px rgba(14, 17, 17, 0.08), 0 1px 10px 0 rgba(4, 177, 240, 0.05),
    0 4px 5px 0 rgba(14, 17, 17, 0.06);

  @media ${DESKTOP_OR_MORE} {
    padding: 26px;
  }
`

const filterItemLabelStyles = css`
  @media ${DESKTOP_OR_MORE} {
    font-size: 20px;
  }
`

const panelStyles = (backgroundColor = 'transparent') => css`
  background-color: ${backgroundColor};
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: background-color 500ms cubic-bezier(0.64, 0.04, 0.35, 1);
  @media ${TABLET_OR_LESS} {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @media ${DESKTOP_OR_MORE} {
    border-radius: 5px;
  }
`

const logoStyles = css`
  width: 100px;
  display: inline-block;
  cursor: pointer;
`

const headingStyles = css`
  font-size: 22px;
  font-weight: 600;
  margin-top: 20px !important;
  margin-bottom: 40px;
  text-align: center;
  @media ${DESKTOP_OR_MORE} {
    margin-top: 10px !important;
    font-size: 32px;

    div {
      display: inline-block;
    }
  }
`

const PlusIcon = () => (
  <img
    src={`${PUBLIC_ASSET_PREFIX}/static/images/plus-icon.svg`}
    alt=""
    css={css`
      fill: #353a3c;
      width: 14px;
      height: 14px;
      margin: 20px 20px;
      @media ${DESKTOP_OR_MORE} {
        width: 18px;
        height: 18px;
        margin: 28px 20px;
      }
    `}
  />
)
const RemoteIcon = () => (
  <img
    src={`${PUBLIC_ASSET_PREFIX}/static/images/remote.svg`}
    alt="Remote"
    css={css`
      width: 56px;
      height: 56px;
      @media ${DESKTOP_OR_MORE} {
        width: 70px;
        height: 70px;
      }
    `}
  />
)
const CovidIcon = () => (
  <img
    src={`${PUBLIC_ASSET_PREFIX}/static/images/covid.svg`}
    alt="COVID"
    css={css`
      width: 56px;
      height: 56px;
      @media ${DESKTOP_OR_MORE} {
        width: 70px;
        height: 70px;
      }
    `}
  />
)
const EntryLevelIcon = () => (
  <img
    src={`${PUBLIC_ASSET_PREFIX}/static/images/new_grad.svg`}
    alt="Entry-level"
    css={css`
      width: 56px;
      height: 56px;
      @media ${DESKTOP_OR_MORE} {
        width: 70px;
        height: 70px;
      }
    `}
  />
)

const NoScenarioIcon = () => (
  <img
    src={`${PUBLIC_ASSET_PREFIX}/static/images/puzzle.svg`}
    alt="None of these"
    css={css`
      width: 56px;
      height: 56px;
      @media ${DESKTOP_OR_MORE} {
        width: 70px;
        height: 70px;
      }
    `}
  />
)

const LoadingHeadline = ({
  text,
  active,
  position,
  disabled,
  marginTop = 55,
  completed = false,
  showCheckmark = true,
  ...rest
}: {
  text: string
  active: boolean
  position: number
  marginTop?: number
  disabled: boolean
  completed?: boolean
  showCheckmark?: boolean
}) => (
  <div
    css={css`
      position: absolute;
      left: 0;
      bottom: ${disabled ? 0 : 35 * position + marginTop + (completed ? 100 : 0)}px;
      width: 100%;
      color: #353a3c;
      font-family: Lato;
      font-size: 13px;
      line-height: 18px;
      opacity: ${disabled || completed ? 0 : active ? 1 : 0.4};
      transition: all 500ms cubic-bezier(0.64, 0.04, 0.35, 1);
      transition-delay: ${disabled ? '2s' : '0s'};
    `}
    {...rest}
  >
    {text}
    {showCheckmark ? (
      <Icon
        size="medium"
        icon="checkmark"
        color="green"
        css={css`
          margin-top: -2px;
          margin-left: 7px;
          height: 8px;
          opacity: ${disabled ? 0 : 1};
          transition: all 500ms cubic-bezier(0.64, 0.04, 0.35, 1);
          transition-delay: 0.5s;
        `}
      />
    ) : null}
  </div>
)

interface Props {
  defaultScreen?: number
  clickedOutside?: boolean
  headerCss?: EmotionStyles
  bodyCss?: EmotionStyles
  footerCss?: EmotionStyles
  advanced?: boolean
  requireKeywordTreatment?: boolean
  onSubmit?: (closeType?: string) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onClose?: (closeType?: string) => void
  onManualSearch?: () => number | undefined
  onShowScreen?: (screen: number) => void
}

interface ContinueButtonProps {
  loading?: boolean
  disabled?: boolean
  autoFocus?: boolean
  children?: React.ReactChild
  extraCss?: EmotionStyles
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const ContinueButton: React.FC<ContinueButtonProps> = ({
  onClick,
  children,
  extraCss,
  autoFocus = false,
  loading = false,
  disabled = false,
  ...rest
}) => (
  <Button
    data-cy="onboarding-modal-submit"
    loading={loading}
    disabled={disabled}
    autoFocus={autoFocus}
    extraCss={extraCss}
    onClick={onClick}
    fluid
    arrow
    oversize
    uppercase
    {...rest}
  >
    {children}
  </Button>
)

const FocusElement = ({ onSubmit }: any) => (
  <input
    type="text"
    tabIndex={0}
    autoFocus
    readOnly
    onKeyDown={(e: any) => {
      if (e.keyCode === 13) onSubmit()
    }}
    css={css`
      height: 0px;
      width: 0px;
      overflow: hidden;
      border: 0 none;
    `}
  />
)

const getScreenInfo = ({
  screen,
  isAdvanced,
  skipRole,
  skipEnvironment,
  skipPhysicalLabor
}: {
  screen?: number
  isAdvanced?: boolean
  skipRole?: boolean
  skipEnvironment?: boolean
  skipPhysicalLabor?: boolean
}) => {
  const screens = []

  //screens.push(SCREEN.LANDING)
  screens.push(SCREEN.SCENARIOS)
  screens.push(SCREEN.NOT_ALONE)
  //screens.push(SCREEN.HOW_QUICKLY)
  screens.push(SCREEN.WHAT_KIND)
  if (!skipRole) screens.push(SCREEN.WHAT_ROLE)
  screens.push(SCREEN.CONFIRM_LOCATION)
  screens.push(SCREEN.JOB_PREFERENCES)
  if (!skipPhysicalLabor) screens.push(SCREEN.PHYSICAL_LABOR)
  if (!skipEnvironment) screens.push(SCREEN.WORKPLACE_PREFERENCES)

  const index = screen ? screens.indexOf(screen) : undefined

  return {
    index,
    prevScreen: index !== undefined && screens.length > 0 ? screens[index - 1] : undefined,
    nextScreen: index !== undefined && screens.length > index ? screens[index + 1] : undefined,
    firstScreen: screens[0],
    lastScreen: screens[screens.length - 1],
    totalScreens: screens.length,
    isFirst: index === 0,
    isLast: index === screens.length - 1
  }
}

const OnboardingFormUiPure: React.FC<Props> = ({
  clickedOutside = false,
  defaultScreen = SCREEN.SCENARIOS,
  headerCss,
  footerCss,
  bodyCss,
  advanced = false,
  requireKeywordTreatment = false,
  onClose,
  onSubmit,
  onManualSearch,
  onShowScreen,
  ...rest
}) => {
  const version = 'v3'
  const tabs = useTabs()
  const [errorMessage, setErrorMessage] = useState('')
  const [matchStep, setMatchStep] = useState(0)
  const [whatKind, setWhatKind] = useState<string | undefined>(undefined)
  const [howQuickly, setHowQuickly] = useState<string | undefined>(undefined)
  const [filterState, filterActions] = useSearchParams()
  const { tempSearchParams, defaultSearchParams, isSearchInProgress } = filterState
  const { setTempSearchParams, cancelSearchParams } = filterActions
  const { updatePersonas } = usePersonaActions()
  const personaState = usePersonaState().settings
  const [tempScenarios, setTempScenarios] = useState(personaState)

  useUpdateEffect(() => {
    cancelSearchParams()
    onClose?.('clickOutside')
  }, [clickedOutside])

  useEffect(() => {
    if (onShowScreen && typeof tabs.activeId === 'number') {
      onShowScreen(tabs.activeId)
    }
  }, [tabs.activeId])

  useEffect(() => {
    setTempSearchParams({ noModal: '1' })
  }, [])

  const submitForm = () => {
    if (matchStep > 0) return
    setErrorMessage('')

    // Example: error handling
    // if (!howQuickly) setErrorMessage('Please choose an option')

    let error = undefined
    if (tabs.activeId === SCREEN.HOW_QUICKLY) {
      Mixpanel.track(`Search Quiz ${version}`, {
        Screen: `${tabs.activeId}`,
        Timeline: howQuickly || 'None'
      })
    } else if (tabs.activeId === SCREEN.WHAT_KIND) {
      Mixpanel.track(`Search Quiz ${version}`, {
        Screen: `${tabs.activeId}`,
        'Role Specificity': whatKind || 'None'
      })
    } else if (tabs.activeId === SCREEN.SCENARIOS) {
      const somethingElse =
        (!tempScenarios.remote && !tempScenarios.newGrad && !tempScenarios.covid) || false

      Mixpanel.track(`Search Quiz ${version}`, {
        Screen: `${tabs.activeId}`,
        'No Scenario': somethingElse ? 'Y' : 'N',
        'Remote Scenario': tempScenarios.remote ? 'Y' : 'N',
        'Covid Scenario': tempScenarios.covid ? 'Y' : 'N',
        'Entry Level Scenario': tempScenarios.newGrad ? 'Y' : 'N'
      })
    } else if (tabs.activeId === SCREEN.WHAT_ROLE) {
      Mixpanel.track(`Search Quiz ${version}`, {
        Screen: `${tabs.activeId}`,
        'Keyword Field Type': tempSearchParams.keywordType,
        'Keyword Field Value': tempSearchParams.keyword
      })
    } else if (tabs.activeId === SCREEN.CONFIRM_LOCATION) {
      Mixpanel.track(`Search Quiz ${version}`, {
        Screen: `${tabs.activeId}`,
        'Location Changed': tempSearchParams.location !== defaultSearchParams.location ? 'Y' : 'N',
        'Radius Changed':
          tempSearchParams.locationRadius !== defaultSearchParams.locationRadius ? 'Y' : 'N',
        'Location Value': tempSearchParams.location,
        'Radius Value': tempSearchParams.locationRadius
      })
    } else if (tabs.activeId === SCREEN.JOB_PREFERENCES) {
      Mixpanel.track(`Search Quiz ${version}`, {
        Screen: `${tabs.activeId}`,
        'Job Type - Part-Time': tempSearchParams.partTime ? 'Y' : 'N',
        'Site Location - Remote': tempSearchParams.remote ? 'Y' : 'N',
        'Compensation - Hourly': tempSearchParams.hourly !== defaultSearchParams.hourly ? 'Y' : 'N'
      })
    } else if (tabs.activeId === SCREEN.PHYSICAL_LABOR) {
      Mixpanel.track(`Search Quiz ${version}`, {
        Screen: `${tabs.activeId}`,
        'Health & Physical - Physical Labor': tempSearchParams.physicalLabor ? 'Y' : 'N'
      })
    } else if (tabs.activeId === SCREEN.WORKPLACE_PREFERENCES) {
      Mixpanel.track(`Search Quiz ${version}`, {
        Screen: `${tabs.activeId}`,
        'Environment - Retail': tempSearchParams.environment?.includes('RETAIL') ? 'N' : 'Y',
        'Environment - Food Services': tempSearchParams.environment?.includes('FOOD_SERVICES')
          ? 'N'
          : 'Y',
        'Environment - Warehouse': tempSearchParams.environment?.includes('WAREHOUSE') ? 'N' : 'Y',
        'Environment - Driver': tempSearchParams.environment?.includes('DRIVER') ? 'N' : 'Y',
        'Environment - Hospitality': tempSearchParams.environment?.includes('HOSPITALITY')
          ? 'N'
          : 'Y'
      })
    } else {
      Mixpanel.track(`Search Quiz ${version}`, {
        Screen: `${tabs.activeId}`
      })
    }

    if (error) {
      setErrorMessage(error)
    } else {
      const screenInfo = getScreenInfo({
        screen: Number(tabs.activeId),
        isAdvanced: advanced,
        skipRole: whatKind === 'Anything',
        skipEnvironment:
          !!tempSearchParams.keyword && !KEYWORD_EXCLUSION_LIST[tempSearchParams.keyword],
        skipPhysicalLabor:
          !!tempSearchParams.keyword && !KEYWORD_EXCLUSION_LIST[tempSearchParams.keyword]
      })

      if (screenInfo.nextScreen) {
        tabs.showTab(screenInfo.nextScreen)
      } else {
        setTempSearchParams({
          entryLevelOnly:
            tempScenarios.somethingElse || !tempScenarios.newGrad
              ? undefined
              : tempScenarios.newGrad,
          remote:
            tempScenarios.somethingElse || !tempScenarios.remote ? undefined : tempScenarios.remote
        })

        tempScenarios.somethingElse =
          (!tempScenarios.remote && !tempScenarios.newGrad && !tempScenarios.covid) || false

        updatePersonas(tempScenarios)

        const animationBaseSpeed = 1000

        setMatchStep(1)

        setTimeout(() => {
          setMatchStep(2)
        }, 0.2 * animationBaseSpeed)
        setTimeout(() => {
          setMatchStep(3)
        }, 1 * animationBaseSpeed)
        setTimeout(() => {
          setMatchStep(4)
        }, 3 * animationBaseSpeed)
        setTimeout(() => {
          setMatchStep(5)
        }, 4 * animationBaseSpeed)
        setTimeout(() => {
          setMatchStep(6)
        }, 5 * animationBaseSpeed)
        setTimeout(() => {
          setMatchStep(7)
        }, 6 * animationBaseSpeed)
        setTimeout(() => {
          onSubmit?.()
        }, 8 * animationBaseSpeed)

        return
      }
    }
  }

  const handleFilterChange = (
    type: string,
    name: string,
    value: string | number | boolean | string[] | undefined,
    selected: boolean = true
  ) => {
    if (name === 'environment') {
      if (!tempSearchParams.environment) tempSearchParams.environment = []
      if (selected) {
        tempSearchParams.environment!.splice(tempSearchParams.environment!.indexOf(value + ''), 1)
      } else {
        tempSearchParams.environment!.push(String(value))
      }
      setTempSearchParams({ environment: [...tempSearchParams.environment!] })
    } else if (name === 'companys' || name === 'titles') {
      setTempSearchParams({ [name]: selected ? [value] : [] })
    } else {
      setTempSearchParams({ [name]: value === '1' ? true : value === '0' ? false : value })
    }
  }

  const screenInfo = getScreenInfo({
    screen: Number(tabs.activeId),
    isAdvanced: advanced,
    skipRole: whatKind === 'Anything',
    skipEnvironment:
      !!tempSearchParams.keyword && !KEYWORD_EXCLUSION_LIST[tempSearchParams.keyword],
    skipPhysicalLabor:
      !!tempSearchParams.keyword && !KEYWORD_EXCLUSION_LIST[tempSearchParams.keyword]
  })

  const handleBack = () => {
    if (matchStep > 0) return
    tabs.showTab(Number(screenInfo.prevScreen))
  }

  return (
    <div css={panelStyles()} {...rest}>
      <div
        css={[
          css`
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 0;
            padding-bottom: 15px;
            @media ${TABLET_OR_LESS} {
              flex-grow: 1;
              overflow-y: auto;
              height: 100%;
              flex: 1 1 auto;
            }
            @media ${DESKTOP_OR_MORE} {
              padding-left: 0;
              padding-right: 0;
              padding-top: 0;
              padding-bottom: 0;
            }
          `,
          bodyCss
        ]}
      >
        <div
          css={css`
            max-width: 450px;
            margin-left: auto;
            margin-right: auto;
            transition: all 500ms cubic-bezier(0.64, 0.04, 0.35, 1);
            opacity: ${matchStep > 0 ? 0 : 1};

            @media ${DESKTOP_OR_MORE} {
              max-width: 100%;
            }
          `}
        >
          <div
            css={[
              css`
                display: none;

                @media ${DESKTOP_OR_MORE} {
                  display: block;
                  padding: 20px 80px;
                  background-color: #fff;
                  box-shadow: 0 2px 4px -1px rgba(14, 17, 17, 0.08),
                    0 1px 10px 0 rgba(4, 177, 240, 0.05), 0 4px 5px 0 rgba(14, 17, 17, 0.06);
                }
              `,
              headerCss
            ]}
          >
            <Button
              unstyled
              data-cy="onboarding-modal-logo"
              onClick={() => {
                onClose?.('clickLogo')
              }}
            >
              <img
                src={`${PUBLIC_ASSET_PREFIX}/static/icons/Joblist_Logo_Black.svg`}
                alt="Joblist"
                css={logoStyles}
              />
            </Button>
            <div
              css={css`
                float: right;
                padding: 10px;
                display: none;
                @media ${DESKTOP_OR_MORE} {
                  display: block;
                }
              `}
            >
              <Button
                data-cy="onboarding-modal-exit"
                onClick={() => onClose?.('clickClose')}
                unstyled
                size="small"
                css={css`
                  opacity: 0.5;
                  &:hover {
                    opacity: 1;
                  }
                `}
              >
                Exit
              </Button>
            </div>
          </div>

          <div
            css={[
              css`
                max-width: 450px;
                margin-left: auto;
                margin-right: auto;
                padding-top: 15px;
                padding-bottom: 5px;
                transition: all 500ms cubic-bezier(0.64, 0.04, 0.35, 1);

                @media ${DESKTOP_OR_MORE} {
                  max-width: 700px;
                  padding-top: 50px;
                  padding-bottom: 30px;
                }
              `,
              headerCss
            ]}
          >
            <Grid
              between="all"
              css={css`
                margin-bottom: -15px;
              `}
            >
              <Grid.Col
                data-cy="onboarding-modal-back"
                onClick={handleBack}
                css={[
                  headerIconStyles,
                  css`
                    padding: 15px;
                    margin-top: -9px;
                    margin-left: -15px;
                    ${screenInfo.isFirst ? `visibility: hidden; pointer-events: none;` : ''}
                  `
                ]}
              >
                <Icon
                  size="medium"
                  icon="arrow"
                  color="light"
                  rotate={-180}
                  css={css`
                    padding: 1px;
                  `}
                />
              </Grid.Col>
              <Grid.Col
                css={css`
                  width: 225px;
                  padding-top: 12px;
                  @media ${TABLET_OR_MORE} {
                    width: 290px;
                  }
                `}
              >
                <ProgressBar current={screenInfo.index! + 1} total={screenInfo.totalScreens} />
              </Grid.Col>
              <Grid.Col
                css={[
                  headerIconStyles,
                  css`
                    padding: 15px;
                    margin-top: -8px;
                    margin-right: -14px;
                  `
                ]}
              >
                <Icon
                  onClick={() => onClose?.('clickClose')}
                  element="button"
                  size="tiny"
                  icon="close"
                  color="dark"
                  css={css`
                    display: none;
                    @media ${TABLET_OR_LESS} {
                      display: block;
                    }
                  `}
                />
              </Grid.Col>
            </Grid>
          </div>

          <TabPanel id={SCREEN.LANDING}>
            <FindYouJob />
            <FocusElement onSubmit={submitForm} />
          </TabPanel>
          <TabPanel id={SCREEN.HOW_QUICKLY}>
            <HowQuickly
              activeId={howQuickly}
              onOptionChange={(howQuickly: string) => {
                setHowQuickly(howQuickly)
              }}
            />
            <FocusElement onSubmit={submitForm} />
          </TabPanel>
          <TabPanel id={SCREEN.SCENARIOS}>
            <Scenarios
              entryLevelScenario={tempScenarios.newGrad!}
              remoteScenario={tempScenarios.remote!}
              covidScenario={tempScenarios.covid!}
              noScenario={tempScenarios.somethingElse!}
              onOptionChange={(key: string, value: boolean) => {
                if (key === 'noScenario' && value === true) {
                  setTempScenarios({
                    covid: false,
                    newGrad: false,
                    remote: false,
                    somethingElse: true
                  })
                } else if (key === 'entryLevelScenario') {
                  setTempScenarios({
                    ...tempScenarios,
                    somethingElse: false,
                    newGrad: value
                  })
                } else if (key === 'remoteScenario') {
                  setTempScenarios({
                    ...tempScenarios,
                    somethingElse: false,
                    remote: value
                  })
                } else if (key === 'covidScenario') {
                  setTempScenarios({ ...tempScenarios, somethingElse: false, covid: value })
                }
              }}
            />
            <FocusElement onSubmit={submitForm} />
          </TabPanel>
          <TabPanel id={SCREEN.NOT_ALONE}>
            <NotAlone
              entryLevelScenario={tempScenarios.newGrad!}
              remoteScenario={tempScenarios.remote!}
              covidScenario={tempScenarios.covid!}
            />
            <FocusElement onSubmit={submitForm} />
          </TabPanel>
          <TabPanel id={SCREEN.WHAT_KIND}>
            <WhatKind
              activeId={whatKind}
              onOptionChange={(whatKind: string) => {
                setWhatKind(whatKind)
              }}
            />
            <FocusElement onSubmit={submitForm} />
          </TabPanel>
          <TabPanel id={SCREEN.WHAT_ROLE}>
            <WhatRole
              keyword={tempSearchParams.keyword!}
              requireKeywordTreatment={requireKeywordTreatment}
              onSubmit={submitForm}
              entryLevelScenario={tempScenarios.newGrad!}
              remoteScenario={tempScenarios.remote!}
              covidScenario={tempScenarios.covid!}
              onUpdateKeyword={(keyword: string, keywordType: string) => {
                setTempSearchParams({ keyword, keywordType })
              }}
              onCountryChange={(searchCountry: string) => {
                setTempSearchParams({ searchCountry })
              }}
            />
          </TabPanel>
          <TabPanel id={SCREEN.CONFIRM_LOCATION}>
            <ConfirmLocation
              location={tempSearchParams.location!}
              locationRadius={tempSearchParams.locationRadius!}
              countryRestrictions={getCountryRestrictions(tempSearchParams.searchCountry)}
              onSubmit={() => {
                submitForm()
              }}
              onLocationChange={(location: string, searchCountry: string) => {
                setTempSearchParams({ location, searchCountry })
              }}
              onLocationRadiusChange={locationRadius => {
                setTempSearchParams({ locationRadius })
              }}
            />
          </TabPanel>
          <TabPanel id={SCREEN.JOB_PREFERENCES}>
            <Heading as="h2" size="medium" css={headingStyles}>
              Are you okay including <div>these types of jobs?</div>
            </Heading>
            <FilterGroup
              collapsable={false}
              css={filterGroupStyles}
              itemLabelCss={filterItemLabelStyles}
              onUpdate={handleFilterChange}
              items={[
                {
                  type: 'toggle',
                  label: 'Part-Time',
                  name: 'partTime',
                  value: tempSearchParams.partTime ? '0' : '1',
                  selected: tempSearchParams.partTime
                }
              ]}
            />
            <FilterGroup
              collapsable={false}
              css={filterGroupStyles}
              itemLabelCss={filterItemLabelStyles}
              onUpdate={handleFilterChange}
              items={[
                {
                  type: 'toggle',
                  label: 'Hourly',
                  name: 'hourly',
                  value: tempSearchParams.hourly ? '0' : '1',
                  selected: tempSearchParams.hourly
                }
              ]}
            />
            <FocusElement onSubmit={submitForm} />
          </TabPanel>
          <TabPanel id={SCREEN.PHYSICAL_LABOR}>
            <Heading as="h2" size="medium" css={headingStyles}>
              Are you okay with <div>physical labor on the job?</div>
            </Heading>
            <FilterGroup
              collapsable={false}
              css={filterGroupStyles}
              itemLabelCss={filterItemLabelStyles}
              onUpdate={handleFilterChange}
              items={[
                {
                  type: 'toggle',
                  label: 'Physical Labor',
                  name: 'physicalLabor',
                  value: tempSearchParams.physicalLabor ? '0' : '1',
                  selected: tempSearchParams.physicalLabor
                }
              ]}
            />
            <Tooltip
              title={<span>What is this?</span>}
              css={css`
                max-width: 400px;
                margin-left: auto;
                margin-right: auto;
              `}
            >
              <p>
                Jobs we’ve identified that often require carrying or lifting heavy objects,
                operating machinery, or continuous physical activity.
              </p>
            </Tooltip>
            <FocusElement onSubmit={submitForm} />
          </TabPanel>
          <TabPanel id={SCREEN.WORKPLACE_PREFERENCES}>
            <Heading as="h2" size="medium" css={headingStyles}>
              Are you okay <div>including these work settings?</div>
            </Heading>
            <FilterGroup
              collapsable={false}
              label={undefined}
              css={filterGroupStyles}
              itemLabelCss={filterItemLabelStyles}
              onUpdate={handleFilterChange}
              items={[
                {
                  type: 'checkbox',
                  label: 'Retail',
                  name: 'environment',
                  value: 'RETAIL',
                  selected: !tempSearchParams.environment?.includes('RETAIL')
                },
                {
                  type: 'checkbox',
                  label: 'Food Services',
                  name: 'environment',
                  value: 'FOOD_SERVICES',
                  selected: !tempSearchParams.environment?.includes('FOOD_SERVICES')
                },
                {
                  type: 'checkbox',
                  label: 'Warehouse',
                  name: 'environment',
                  value: 'WAREHOUSE',
                  selected: !tempSearchParams.environment?.includes('WAREHOUSE')
                },
                {
                  type: 'checkbox',
                  label: 'Driver',
                  name: 'environment',
                  value: 'DRIVER',
                  selected: !tempSearchParams.environment?.includes('DRIVER')
                },
                {
                  type: 'checkbox',
                  label: 'Hospitality',
                  name: 'environment',
                  value: 'HOSPITALITY',
                  selected: !tempSearchParams.environment?.includes('HOSPITALITY')
                }
              ]}
            />
            <FocusElement onSubmit={submitForm} />
          </TabPanel>
        </div>
        <div
          css={css`
            position: absolute;
            top: 100px;
            left: calc(50% - 200px);
            width: 400px;
            display: flex;
            opacity: ${matchStep > 1 ? 1 : 0};
            transition: all 1500ms cubic-bezier(0.64, 0.04, 0.35, 1);
            transition-delay: 0.2s;
            @media ${DESKTOP_OR_MORE} {
              top: 150px;
            }
          `}
        >
          {matchStep > 1 ? (
            <div
              css={css`
                width: 100%;
                color: #353a3c;
                font-family: Lato;
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                text-align: center;
                align-self: center;
                margin: 0 auto;
                z-index: 3;
              `}
            >
              <div
                css={css`
                  position: relative;
                `}
              >
                <div
                  css={css`
                    position: absolute;
                    top: -270px;
                    left: 0;
                    z-index: -1;
                    width: 100%;
                    opacity: ${matchStep > 1 && matchStep < 6 ? 1 : 0};
                    transition: all 1500ms cubic-bezier(0.64, 0.04, 0.35, 1);
                    transform: scale(0.4);
                    @media ${DESKTOP_OR_MORE} {
                      top: -270px;
                      transform: scale(0.6);
                    }
                  `}
                >
                  {/* <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: cloudAnimationData,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                      }
                    }}
                    height={640}
                    width={577}
                  /> */}
                </div>
                <div
                  css={css`
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    opacity: ${matchStep > 1 && matchStep < 6 ? 1 : 0};
                    transition: all 1500ms cubic-bezier(0.64, 0.04, 0.35, 1);
                    /* transform: scale(0.7);
                    @media ${DESKTOP_OR_MORE} {
                      transform: scale(1);
                    } */
                  `}
                >
                  {/* <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: loadingAnimationData,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                      }
                    }}
                    height={100}
                    width={100}
                  /> */}
                  <div
                    css={css`
                      position: relative;
                      height: 100px;
                      align-self: center;
                      margin: 0 auto;
                      padding-top: 10px;
                    `}
                  >
                    {tempScenarios.remote ? (
                      <>
                        <RemoteIcon />
                        {tempScenarios.covid || tempScenarios.newGrad ? <PlusIcon /> : null}
                      </>
                    ) : null}
                    {tempScenarios.covid ? (
                      <>
                        <CovidIcon />
                        {tempScenarios.newGrad ? <PlusIcon /> : null}
                      </>
                    ) : null}
                    {tempScenarios.newGrad ? (
                      <>
                        <EntryLevelIcon />
                      </>
                    ) : null}
                    {!tempScenarios.remote && !tempScenarios.covid && !tempScenarios.newGrad ? (
                      <>
                        <NoScenarioIcon />
                      </>
                    ) : null}
                  </div>
                  <div
                    css={css`
                      margin-top: 80px;
                      font-size: 19px;

                      @media ${DESKTOP_OR_MORE} {
                        font-size: 28px;
                      }
                    `}
                  >
                    Finding jobs you'll love....
                  </div>
                </div>
                <div
                  css={css`
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    opacity: ${matchStep >= 6 ? 1 : 0};
                    transition: all 1500ms cubic-bezier(0.64, 0.04, 0.35, 1);
                  `}
                >
                  <div
                    css={css`
                      position: relative;
                      width: 100px;
                      height: 100px;
                      align-self: center;
                      margin: 0 auto;
                      background: url('${PUBLIC_ASSET_PREFIX}/static/images/loading-checkmark-front.png') no-repeat 50% 0;
                      background-size: 80px;

                      @media ${DESKTOP_OR_MORE} {
                        background-size: 90px;
                      }
                    `}
                  >
                    <div
                      css={css`
                        position: absolute;
                        top: 10px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        z-index: -1;
                        content: ' ';
                        background: url('${PUBLIC_ASSET_PREFIX}/static/images/loading-checkmark-back.png') no-repeat 50% 0;
                        transition: all 1500ms cubic-bezier(0.64, 0.04, 0.35, 1);
                        background-size: 60px;

                        @media ${DESKTOP_OR_MORE} {
                          background-size: 70px;
                        }
                      `}
                    />
                  </div>
                  <div
                    css={css`
                      margin-top: 80px;

                      @media ${DESKTOP_OR_MORE} {
                        font-size: 24px;
                      }
                    `}
                  >
                    Done!
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div
        css={[
          css`
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 15px;
            padding-right: 15px;
            @media ${TABLET_OR_LESS} {
              flex: 0 0 auto;
              box-shadow: 0 -1px 3px 1px rgba(0, 0, 0, 0.07);
              z-index: 9;
              ${matchStep > 0 ? `box-shadow: none;` : ''};
            }
          `,
          footerCss
        ]}
      >
        <div
          css={css`
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0px;
            @media ${DESKTOP_OR_MORE} {
              margin-top: 40px;
            }
          `}
        >
          {errorMessage ? <div css={errorViewStyles}>{errorMessage}</div> : null}

          {tabs.activeId === SCREEN.SCENARIOS ? (
            <ContinueButton
              loading={isSearchInProgress}
              onClick={submitForm}
              extraCss={css`
                &:focus,
                &:active {
                  background-color: ${BLACK};
                  border-color: ${BLACK};

                  &:hover {
                    background-color: #27292a;
                  }
                }
              `}
            >
              Next
            </ContinueButton>
          ) : screenInfo.isLast || matchStep > 0 ? (
            <>
              <ContinueButton
                loading={isSearchInProgress}
                onClick={submitForm}
                extraCss={[
                  matchStep > 0
                    ? css`
                        display: none;
                      `
                    : null
                ]}
              >
                Get Matches
              </ContinueButton>
              <div
                css={css`
                  position: relative;
                  opacity: 0;
                  transition: all 500ms cubic-bezier(0.64, 0.04, 0.35, 1);
                  ${matchStep > 0
                    ? `
                      opacity: 1;`
                    : ''}
                `}
              >
                <div
                  css={css`
                    display: none;
                    ${matchStep > 0
                      ? `
                      display: block;`
                      : ''}
                  `}
                >
                  <LoadingHeadline
                    text="MATCHING KEYWORDS"
                    active={matchStep === 3}
                    position={matchStep - 3}
                    disabled={matchStep < 3}
                    completed={matchStep >= 6}
                  />
                  <LoadingHeadline
                    text="FILTERING TO YOUR LOCATION"
                    active={matchStep === 4}
                    position={matchStep - 4}
                    disabled={matchStep < 4}
                    completed={matchStep >= 6}
                  />
                  <LoadingHeadline
                    text="APPLYING YOUR PREFERENCES"
                    active={matchStep === 5}
                    position={matchStep - 5}
                    disabled={matchStep < 5}
                    completed={matchStep >= 6}
                  />
                  <LoadingHeadline
                    text="LOADING JOB MATCHES"
                    active={matchStep >= 6}
                    position={0}
                    disabled={matchStep < 6}
                    showCheckmark={false}
                    css={css`
                      text-align: center;
                    `}
                  />
                  <div
                    css={css`
                      position: relative;
                      display: flex;
                      height: 64px;

                      &:before {
                        position: absolute;
                        top: 30px;
                        left: 0;
                        content: ' ';
                        width: 100%;
                        height: 30px;
                        background: #f7f9fc;
                        z-index: 1;
                      }
                    `}
                  >
                    <ProgressBar
                      current={matchStep >= 5 ? 3 : matchStep <= 2 ? 0 : matchStep - 2}
                      total={3}
                      css={css`
                        align-self: center;
                        z-index: 2;
                        ${matchStep === 1 ? 'height: 64px;' : ''}
                      `}
                      barCss={css`
                        min-width: 4px;
                        opacity: 1;
                        ${matchStep === 1 ? 'opacity: 0;' : ''}
                        ${matchStep >= 2 ? 'transition-delay: 0;' : 'transition-delay: 1s;'}
                        ${matchStep >= 6 ? 'background-color: #33C986;' : ''}
                      `}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <ContinueButton
              loading={isSearchInProgress}
              onClick={submitForm}
              extraCss={css`
                &:focus,
                &:active {
                  background-color: ${BLACK};
                  border-color: ${BLACK};

                  &:hover {
                    background-color: #27292a;
                  }
                }
              `}
            >
              Next
            </ContinueButton>
          )}
        </div>
      </div>
    </div>
  )
}

export const OnboardingFormUi: React.FC<Props> = props => {
  const screenInfo = getScreenInfo({})

  return (
    <Tabs total={screenInfo.totalScreens} defaultId={props.defaultScreen}>
      <OnboardingFormUiPure {...props} />
    </Tabs>
  )
}
