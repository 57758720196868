import React from 'react'
import { css } from '@emotion/core'
import { useTabs } from './Tabs'

export interface ITabItemProps {
  id: number | string
}

export const TabItem: React.FC<ITabItemProps> = props => {
  const { toggleTab } = useTabs()
  return (
    <div
      onClick={() => toggleTab(props.id)}
      css={css`
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
        user-select: none;
      `}
    >
      {props.children}
    </div>
  )
}
