import React from 'react'
import { css } from '@emotion/core'
import { RadioTile } from '../../../../components/common/RadioTile'
import Grid from '../../../../components/common/Grid'
import Heading from '../../../../components/common/Heading'

import env from '~/constants/env'
const PUBLIC_ASSET_PREFIX = env.PUBLIC_ASSET_PREFIX
import { DESKTOP_OR_MORE } from '~/constants/theme.styles'

interface Props {
  entryLevelScenario: boolean
  remoteScenario: boolean
  covidScenario: boolean
  noScenario: boolean
  onOptionChange?: (name: string, value: boolean) => void
}

export const Scenarios: React.FC<Props> = ({
  entryLevelScenario,
  remoteScenario,
  covidScenario,
  noScenario,
  onOptionChange
}) => (
  <div>
    <Heading
      as="h2"
      css={css`
        font-size: 22px;
        font-weight: 600;
        margin-top: 20px !important;
        margin-bottom: 40px;
        text-align: center;

        span {
          display: block;
        }

        @media ${DESKTOP_OR_MORE} {
          margin-top: 0px !important;
          font-size: 32px;
        }
      `}
    >
      Let’s find you a job! <span>Select all that apply to you:</span>
    </Heading>
    <div
      css={css`
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;
        @media ${DESKTOP_OR_MORE} {
          max-width: 825px;
        }
      `}
    >
      <Grid gap={10}>
        <Grid.Col xs={12} md={12} lg={6}>
          <RadioTile
            data-cy="onboarding-scenario-covid"
            isInactive={noScenario}
            isSelected={covidScenario}
            onClick={() => onOptionChange?.('covidScenario', !covidScenario)}
          >
            <p
              css={css`
                padding: 15px 13px 15px 80px;
                background: url('${PUBLIC_ASSET_PREFIX}/static/images/covid.svg') no-repeat 15px 50%;
                background-size: 45px auto;
                line-height: 24px;
                font-size: 15px;
                height: 80px;
                @media (min-width: 375px) {
                  font-size: 16px;
                }
                @media ${DESKTOP_OR_MORE} {
                  background-position: 30px 50%;
                  padding: 20px 13px 20px 90px;
                  height: 90px;
                  font-size: 18px;
                }
                span {
                  display: block;
                }
              `}
            >
              I’m <strong>unemployed</strong> <span>due to Covid-19</span>
            </p>
          </RadioTile>
        </Grid.Col>
        <Grid.Col xs={12} md={12} lg={6}>
          <RadioTile
            data-cy="onboarding-scenario-entry-level"
            isInactive={noScenario}
            isSelected={entryLevelScenario}
            onClick={() => onOptionChange?.('entryLevelScenario', !entryLevelScenario)}
          >
            <p
              css={css`
                padding: 15px 13px 15px 80px;
                background: url('${PUBLIC_ASSET_PREFIX}/static/images/new_grad.svg') no-repeat 15px 50%;
                background-size: 45px auto;
                line-height: 24px;
                font-size: 15px;
                align-self: center;
                height: 75px;
                @media (min-width: 375px) {
                  font-size: 16px;
                }
                @media ${DESKTOP_OR_MORE} {
                  background-position: 30px 50%;
                  padding: 20px 13px 20px 90px;
                  height: 90px;
                  font-size: 18px;
                }
                span {
                  display: block;
                }
              `}
            >
              I’m a recent grad looking for{' '}
              <span>
                <strong>entry-level</strong> opportunities
              </span>
            </p>
          </RadioTile>
        </Grid.Col>
        <Grid.Col xs={12} md={12} lg={6}>
          <RadioTile
            data-cy="onboarding-scenario-remote"
            isInactive={noScenario}
            isSelected={remoteScenario}
            onClick={() => onOptionChange?.('remoteScenario', !remoteScenario)}
          >
            <p
              css={css`
                padding: 25px 13px 25px 80px;
                background: url('${PUBLIC_ASSET_PREFIX}/static/images/remote.svg') no-repeat 15px 50%;
                background-size: 45px auto;
                line-height: 24px;
                font-size: 15px;
                height: 75px;
                @media (min-width: 375px) {
                  font-size: 16px;
                }
                @media ${DESKTOP_OR_MORE} {
                  background-position: 30px 50%;
                  padding: 32px 13px 20px 90px;
                  height: 90px;
                  font-size: 18px;
                }
              `}
            >
              I’m open to <strong>remote</strong> work
            </p>
          </RadioTile>
        </Grid.Col>
        <Grid.Col xs={12} md={12} lg={6}>
          <RadioTile
            data-cy="onboarding-scenario-none"
            isInactive={false}
            isSelected={noScenario}
            onClick={() => onOptionChange?.('noScenario', !noScenario)}
          >
            <p
              css={css`
                padding: 25px 13px 25px 80px;
                background: url('${PUBLIC_ASSET_PREFIX}/static/images/puzzle.svg') no-repeat 15px 50%;
                background-size: 45px auto;
                line-height: 24px;
                font-size: 15px;
                height: 75px;
                @media (min-width: 375px) {
                  font-size: 16px;
                }
                @media ${DESKTOP_OR_MORE} {
                  background-position: 30px 50%;
                  padding: 32px 13px 20px 90px;
                  height: 90px;
                  font-size: 18px;
                }
              `}
            >
              None of these
            </p>
          </RadioTile>
        </Grid.Col>
      </Grid>
    </div>
  </div>
)
