import React from 'react'
import { css } from '@emotion/core'
import Icon from '../../../components/common/Icon'

type IProps = {
  isSelected?: boolean
  isInactive?: boolean
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void
}

const styles = css`
  width: 100%;
  cursor: pointer;
  user-select: none;
`

const outerBoxStyles = css`
  position: relative;
  border: 2px solid transparent;
  border-radius: 9px;
  &:hover {
    border-color: #8353fd;
  }
`

const selectedOuterBoxStyles = css`
  border-color: #8353fd;
  box-shadow: 0 3.33px 19.63px 10px rgba(62, 52, 88, 0.07),
    10px 4.67px 11.07px 0 rgba(128, 83, 253, 0.08), 0 6px 12px 0 rgba(94, 87, 114, 0.09);
`

const inactiveOuterBoxStyles = css``

const innerBoxStyles = css`
  height: 100%;
  box-shadow: 0 2px 4px rgba(14, 17, 17, 0.08), 0 1px 10px rgba(4, 177, 240, 0.05),
    0 4px 5px rgba(14, 17, 17, 0.06), inset 0 0 3px rgba(239, 239, 239, 0.4);
  border-radius: 7px;
  border: 1px solid #e7e9f0;
  background: #fff;
  transition: opacity 0.3s;
`

const selectedInnerBoxStyles = css`
  opacity: 1;
  box-shadow: 0 2px 4px rgba(14, 17, 17, 0.08), 0 1px 10px rgba(4, 177, 240, 0.05),
    0 4px 5px rgba(14, 17, 17, 0.06), inset 0 0 0 2px rgba(99, 105, 167, 0.25);
`

const inactiveInnerBoxStyles = css`
  opacity: 0.4;
  &:hover {
    opacity: 0.85;
  }
`

const checkmarkStyles = css`
  position: absolute;
  top: 15px;
  right: 15px;
`

const RadioTile: React.FC<IProps> = ({ isSelected, isInactive, onClick, children, ...rest }) => {
  return (
    <div css={styles} {...rest}>
      <div
        css={[
          outerBoxStyles,
          isSelected ? selectedOuterBoxStyles : undefined,
          isInactive ? inactiveOuterBoxStyles : undefined
        ]}
      >
        <div
          css={[
            innerBoxStyles,
            isSelected ? selectedInnerBoxStyles : undefined,
            isInactive ? inactiveInnerBoxStyles : undefined
          ]}
          onClick={e => onClick?.(e)}
        >
          {isSelected ? (
            <Icon size="medium" icon="checkmark" color="purple" css={checkmarkStyles} />
          ) : null}
          {children}
        </div>
      </div>
    </div>
  )
}

export { RadioTile }
