import React from 'react'
import { css } from '@emotion/core'
import { RadioTile } from '../../../../components/common/RadioTile'
import { Tooltip } from '../../../../components/common/Tooltip'
import Grid from '../../../../components/common/Grid'
import Heading from '../../../../components/common/Heading'

import env from '~/constants/env'
const PUBLIC_ASSET_PREFIX = env.PUBLIC_ASSET_PREFIX
import { DESKTOP_OR_MORE } from '~/constants/theme.styles'

interface Props {
  activeId?: string
  onOptionChange?: (name: string) => void
}

export const HowQuickly: React.FC<Props> = ({ activeId, onOptionChange }) => (
  <div>
    <Heading
      as="h2"
      css={css`
        font-size: 22px;
        margin-top: 20px !important;
        margin-bottom: 40px;
        text-align: center;
        @media ${DESKTOP_OR_MORE} {
          margin-top: 10px !important;
          font-size: 23px;

          div {
            display: inline-block;
          }
        }
      `}
    >
      How quickly do you want <div>to start a new job?</div>
    </Heading>
    <Grid
      gap={17}
      css={css`
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;
        @media ${DESKTOP_OR_MORE} {
          max-width: 825px;
        }
      `}
    >
      <Grid.Col xs={6} md={6} gap={17}>
        <RadioTile
          isInactive={activeId && activeId !== 'ASAP' ? true : false}
          isSelected={activeId === 'ASAP' ? true : false}
          onClick={() => onOptionChange?.('ASAP')}
        >
          <p
            css={css`
              padding: 105px 13px 20px 13px;
              background: url('${PUBLIC_ASSET_PREFIX}/static/images/onboarding-hourglass.svg') no-repeat 100% 20%;
              line-height: 20px;
              font-size: 15px;
              @media (min-width: 375px) {
                font-size: 16px;
              }
            `}
          >
            I want/need a <br />
            new job <strong>now</strong>
          </p>
        </RadioTile>
      </Grid.Col>
      <Grid.Col xs={6} md={6} gap={17}>
        <RadioTile
          isInactive={activeId && activeId !== 'Browsing' ? true : false}
          isSelected={activeId === 'Browsing' ? true : false}
          onClick={() => onOptionChange?.('Browsing')}
        >
          <p
            css={css`
              padding: 105px 13px 20px 13px;
              background: url('${PUBLIC_ASSET_PREFIX}/static/images/onboarding-compass.svg') no-repeat 100% 20%;
              line-height: 20px;
              font-size: 15px;
              @media (min-width: 375px) {
                font-size: 16px;
              }
            `}
          >
            I'm just
            <br />
            exploring
          </p>
        </RadioTile>
      </Grid.Col>
    </Grid>
    {activeId ? (
      <Tooltip
        title={<span>How we can help</span>}
        arrowPlacement={activeId === 'ASAP' ? 'top-start' : 'top-end'}
      >
        <p>
          {activeId === 'ASAP'
            ? 'We collect millions of jobs each day from across the web so you have access to the latest opportunities.'
            : "Take your time browsing what's available in a single place. We have jobs in nearly every industry."}
        </p>
      </Tooltip>
    ) : null}
  </div>
)
